@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Flow+Circular&family=Manrope:wght@200..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}


@layer components {
  .btn {
    @apply w-full font-manrope px-3 py-2 rounded-lg text-white bg-atlas_orange disabled:bg-atlas_disabled_orange font-semibold hover:brightness-75;
  }
}

/* Custom class to hide scrollbar */
.scrollbar-hide {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* Base (light mode) */
::-webkit-calendar-picker-indicator {
  filter: none; /* no inversion for light mode */
}

/* When user prefers dark mode */
@media (prefers-color-scheme: dark) {
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}
@layer utilities {
  .slide-in-left {
    animation: slide-in-left 0.5s ease-out forwards;
  }
  .slide-in-right {
    animation: slide-in-right 0.5s ease-out forwards;
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(5%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(-5%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
